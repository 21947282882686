import { upload } from "@testing-library/user-event/dist/upload";
import { CREDITS } from "../../../constants/status";
import { api_get_trainings, api_train } from "../../../queries/train";
import Asset from "../../models/asset/Asset";
import axios from "axios";
import { API } from "../../../constants/system";
import { toJS } from "mobx";

export const trainStoreActions = self => ({  

  async train() {
    try {
      self.set("loading", true)

      const data = {
        triggerWord: self.productContext,
        steps: self.steps,
        assetIds: toJS(self.assetIds)
      }

      const res = await api_train(self.accountStore.auth0AccessToken, data) 

      if (res.error) throw res

      self.set("loading", false)

      return res

    } catch (err) {
      self.set("loading", false)
      if (err.response.data.message === CREDITS.OUT_OF_TOKENS) {
        self.set("launchTrainingOnMount", true)
        self.guiStore.openPaywallModal()
      }
      
    }
  },

  async getTrainings() {
    try {
      const res = await api_get_trainings(self.accountStore.auth0AccessToken)

      self.set('trainings', res.data.data.map((training) => ({
        ...training,
        product: self.productsFactory.addUpdateProduct(training.product)

      })))
    } catch (err) {
      console.log(err)
    }
  },

  async uploadFiles(files) {
    try {

      self.set('uploadingFiles', true)
      const promises = []


      files.map((file) => {
        
        if (self.assetIds.includes(file.id)) {
          promises.push(new Promise((resolve) => resolve({ data: { data: { id: file.id } } })))
        } else {
          const formData = new FormData();
          formData.append('file', file.file);
          const p = axios({
            method: 'post',
            url: `${API}/training-asset`,
            data: formData,
            headers: {
              'Authorization': `Bearer ${self.accountStore.auth0AccessToken}`,
            }
          })
          promises.push(p)
        }
      })

      const res = await Promise.all(promises)

      const assetIds = res.map((asset) => asset.data.data.id)
      console.log(assetIds)
      self.set('assetIds', assetIds)

      self.set('uploadingFiles', false)

      return assetIds
    } catch (err) {
      console.log(err)
      self.set('uploadingFiles', true)
      return null
    }
  },

  set(key, value) {
    self[key] = value
  }

})

function getRandomNumber() {
  return Math.floor(Math.random() * (100000 - 10000 + 1)) + 10000;
}

